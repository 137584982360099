import React from 'react';
import { Container, Row } from 'react-bootstrap';
const Cancel: React.FC = () => {
  return (
    <Container>
      <Row>
        <h1>Payment Canceled</h1>
        <p>Your payment was not completed. Please try again.</p>
      </Row>
    </Container>
  );
};

export default Cancel;