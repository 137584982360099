import React from 'react';
import { Container } from 'react-bootstrap';

const Terms: React.FC = () => {

  return (
    <Container>
      <div>
        <h1>Terms</h1>
      </div>
    </Container>
  );
};

export default Terms;