import React, { useEffect, useState, useRef, useCallback } from 'react';
import useImageApi from '../../../managers/ImageManager';
import { Container, Row, Col } from 'react-bootstrap';
import Loading, { LoadingStates } from '../../../components/Loading';

const ImageListComponent: React.FC = () => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { getImages } = useImageApi();
  const [imagesData, setImagesData] = useState<any[]>([]);
  const lastKeyRef = useRef<Record<string, any> | null>(null); // Tracks pagination key persistently
  const baseUrl = "https://dev-images.wallscapes.ai/";

  const isFetching = useRef(false); // Prevents multiple fetches at the same time
  const hasMoreImages = useRef(true); // Tracks whether there are more images to load
  const observer = useRef<IntersectionObserver | null>(null); // Holds the IntersectionObserver

  // Fetch images function
  const fetchImages = useCallback(async () => {
    if (isFetching.current || !hasMoreImages.current) return;

    isFetching.current = true; // Block further fetches
    setLoadingState(LoadingStates.LOADING);

    try {
      const data = await getImages(10, lastKeyRef?.current || undefined); // Pass lastKey for pagination

      console.log("Previous lastKey:", lastKeyRef?.current);
      console.log("Fetched lastKey from API:", data.lastKey);

      if (data.items.length === 0) {
        console.log("No items returned from the API.");
        hasMoreImages.current = false; // Stop further requests
      } else {
        await setImagesData((prevImages) => [...prevImages, ...data.items]); // Append new images to the list

        if (data.lastKey) {
          lastKeyRef.current = data.lastKey; // Update the lastKey persistently
        } else {
          console.log("No lastKey returned. Ending pagination.");
          hasMoreImages.current = false; // Stop further fetches if no `lastKey`
        }
      }

      setLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      hasMoreImages.current = false;
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage("Error fetching images: " + error);
      console.error("Error fetching images:", error);
    } finally {
      isFetching.current = false; // Reset the fetching flag
    }
  }, [lastKeyRef.current, getImages]);

  // Effect to fetch initial images when component mounts
  useEffect(() => {
    if (imagesData.length === 0) {
      fetchImages(); // Fetch initial images when the component mounts
    }
  }, [fetchImages]);

  // IntersectionObserver callback for the last image
  const lastImageElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetching.current && hasMoreImages.current) {
        console.log("Last image in view. Fetching more images...");
        fetchImages();
      }
    }, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    });

    if (node) observer.current.observe(node);
  }, [fetchImages]);

  if ((loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) && imagesData.length === 0) {
    return <div><Loading state={loadingState} message={loadingMessage} /></div>;
  }

  if (!imagesData || imagesData.length === 0) {
    return <div>No Images found.</div>;
  }

  return (
    <Container>
      <Row>
        {imagesData.map((image, index) => {
          return (
            <Col key={index} xs={12} sm={6} md={4} lg={3} ref={imagesData.length === index + 1 ? lastImageElementRef : null}>
              <div className="image-item mb-2">
                <img
                  src={`${baseUrl + image.imageKey}?w=248&fit=crop&auto=format`}
                  srcSet={`${baseUrl + image.imageKey}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={`AI Generated Image ${index}`}
                  loading="lazy"
                  className="img-fluid"
                />
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ImageListComponent;
