import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SiteMap: React.FC = () => {
  return (
    <div className="py-3">
      <Container>
        <Row>
          {/* Help Section */}
          <Col md={4} className="text-center text-md-start">
            <h5>Help</h5>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/returns">Returns Policy</Nav.Link>
              <Nav.Link as={Link} to="/shipping">Shipping Policy</Nav.Link>
              <Nav.Link as={Link} to="/shipping">Intellectual Property Policy</Nav.Link>
              <Nav.Link href="mailto:support@wallscapes.ai">support@wallscapes.ai</Nav.Link>
            </Nav>
          </Col>

          {/* Shop Section */}
          <Col md={4} className="text-center text-md-start">
            <h5>Shop</h5>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/products">Shop All Products</Nav.Link>
              <Nav.Link as={Link} to="/products/custom">Design Your Own</Nav.Link>
            </Nav>
          </Col>

          {/* About Section */}
          <Col md={4} className="text-center text-md-start">
            <h5>About</h5>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/about">Company</Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Footer: React.FC = () => {
  return (
    <footer className="bg-light mt-auto">
      <SiteMap />
      <div className="py-3 text-center border-top">
        <Container>
          <Nav className="justify-content-center mb-2">
            <Nav.Link as={Link} to="/terms">Terms of Service</Nav.Link>
            <Nav.Link as={Link} to="/privacy">Privacy Policy</Nav.Link>
          </Nav>
          <p className="mb-0">
            &copy; {new Date().getFullYear()} Wallscapes. All rights reserved.
          </p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
