import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import useProductApi from '../../../managers/ProductManager';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { formatCurrency } from '../../../utilities/formatCurrency';
import Loading, { LoadingStates } from '../../../components/Loading';

interface ProductListProps {
  userId?: string | null; // Make `userId` optional and allow null
}

const ProductList: React.FC<ProductListProps> = ({ userId }) => {
  const [loadingState, setLoadingState] = useState<typeof LoadingStates[keyof typeof LoadingStates]>(LoadingStates.LOADING);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { getProducts, getUserProducts } = useProductApi();
  const [products, setProducts] = useState<any[]>([]);
  const lastKeyRef = useRef<Record<string, any> | null>(null); // Persistent pagination key
  const isFetching = useRef(false); // Prevents multiple fetches at the same time
  const hasMoreProducts = useRef(true); // Tracks whether there are more products to load
  const observer = useRef<IntersectionObserver | null>(null); // Holds the IntersectionObserver
  const [productImgUrl, setProductImgUrl] = useState('/logo512.png');
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    getItemQuantity,
    addToCart,
    removeFromCart,
    openCart,
  } = useShoppingCart();

  // Fetch products function
  const fetchProducts = useCallback(async () => {
    if (isFetching.current || !hasMoreProducts.current) return;

    isFetching.current = true; // Prevents concurrent fetches
    setLoadingState(LoadingStates.LOADING);
    console.log(userId)
    try {
      const data = userId
        ? await getUserProducts(userId, 10, lastKeyRef?.current || undefined)
        : await getProducts(10, lastKeyRef?.current || undefined);

      if (data.items.length === 0) {
        console.log("No items returned from the API.");
        hasMoreProducts.current = false; // Stop further requests
      } else {
        setProducts((prevProducts) => [...prevProducts, ...data?.items]);

        if (data.lastKey) {
          lastKeyRef.current = data.lastKey; // Update pagination key persistently
        } else {
          console.log("No lastKey returned. Ending pagination.");
          hasMoreProducts.current = false; // Stop further fetches if no `lastKey`
        }
      }
      setLoadingState(LoadingStates.SUCCESS);
    } catch (error) {
      hasMoreProducts.current = false
      setLoadingState(LoadingStates.ERROR);
      setLoadingMessage("Error fetching products: " + error);
      console.error("Error fetching products:", error);
    } finally {
      isFetching.current = false;
    }
  }, [lastKeyRef.current, getProducts]);

  // Effect to fetch initial products when component mounts
  useEffect(() => {
    if (products.length === 0) {
      fetchProducts(); // Fetch initial products when the component mounts
    }
  }, [fetchProducts]);

  const handleImageClick = (productUrl: string) => {
    console.log(productUrl);
    setProductImgUrl(productUrl);
    toggleModal();
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // IntersectionObserver callback for the last product
  const lastProductElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetching.current && hasMoreProducts.current) {
          console.log("Last product in view, fetching more");
          fetchProducts(); // Fetch more products when the last product is visible
        }
      },
      {
        root: null,
        rootMargin: "200px", // Trigger earlier for smoother loading
        threshold: 0,
      }
    );

    if (node) observer.current.observe(node);
  }, [fetchProducts]);

  if ((loadingState === LoadingStates.LOADING || loadingState === LoadingStates.ERROR) && products.length === 0) {
    return <div><Loading state={loadingState} message={loadingMessage} /></div>;
  }

  if (!products || products.length === 0) {
    return <div>No products found.</div>;
  }

  return (
    <Container>
      <Row>
        {products.map((product, index) => {
          const quantity = getItemQuantity(product.productId);
          return (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              ref={products.length === index + 1 ? lastProductElementRef : null}
            >
              <Card className="mb-2">
                <Card.Img
                  variant="top"
                  src={product.previewImageUrls}
                  alt={`Product ${index}`}
                  loading="lazy"
                  className="img-fluid ws-img-clickable"
                  onClick={() => handleImageClick(product.previewImageUrls[0])}
                />
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-baseline mb-3">
                    <span className="fs-5">{product.name || product.title}</span>
                    <span className="ms-2 text-muted">{formatCurrency(product?.price)}</span>
                  </Card.Title>
                  <div className="mt-auto">
                    <div className="d-flex align-items-center flex-column" style={{ gap: ".5rem" }}>
                      {quantity === 0 ? (
                        <Button
                          className="w-100"
                          onClick={() =>
                            addToCart({
                              productId: product?.productId,
                              productUserId: product?.userId,
                              name: product.name || product.title,
                              description: product.description,
                              catalogItemId: product?.catalogItemId,
                              catalogItemVariantId: product?.catalogItemVariantId,
                              price: product?.price,
                              quantity: 1,
                              metadata: { ...product?.metadata, previewImageUrls: product?.previewImageUrls },
                            })
                          }
                        >
                          + Add To Cart
                        </Button>
                      ) : (
                        <Button
                          onClick={() => removeFromCart(product.productId)}
                          className="w-100"
                          variant="secondary"
                        >
                          - Remove From Cart
                        </Button>
                      )}
                      <Button
                        onClick={() => openCart()}
                        className="w-100"
                        variant="outline"
                        size="sm"
                      >
                        View Cart
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Modal show={isModalOpen} onHide={toggleModal} fullscreen>
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <img src={productImgUrl} alt="Generated Product" className="img-fluid ws-img-clickable" onClick={toggleModal} />
          <Button
            variant="secondary"
            className="position-absolute top-0 end-0 m-2"
            onClick={toggleModal}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProductList;
