import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const OrderError: React.FC = () => {
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (location.state?.errorMessage) {
      setError(location.state.errorMessage);
    } else {
      setError('Unknown error has occurred.');
    }
  }, [location.state]);

  return (
    <Container>
      <Row className="mb-4">
        <h1>We apologize.</h1>
        <p>There was a problem submitting your order to the print vendor. Customer Service has been notified and will be following up.  If you do not receive an email within 24 hours, please send an email to support@wallscapes.ai.</p>
        <small>{error}</small>
      </Row>
    </Container>
  );
};

export default OrderError;
