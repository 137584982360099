import axios from 'axios';
import { loadStripe, Stripe as StripeClient } from '@stripe/stripe-js';
import { getEnvironment } from './EnvironmentManager';
import { LineItem, Payment } from './OrderManager';
import { useSessionStorage } from '../hooks/useSessionStorage';

interface ApiUrls {
  [key: string]: string;
}

const paymentApiUrls: ApiUrls = {
  localhost: 'https://api.wallscapes.ai/dev/payment/stripe',
  dev: 'https://api.wallscapes.ai/dev/payment/stripe',
  prod: 'https://api.wallscapes.ai/dev/payment/stripe'
};

const getPaymentApiUrl = () => {
  const environment = getEnvironment();
  return paymentApiUrls[environment];
};

// Replace with your actual Stripe public key
const stripePromise = loadStripe('pk_test_yiRd46cWA0sa6VOnzgbK4rw3');

const usePaymentApi = () => {

  const [paymentSessionId, setPaymentSessionId] = useSessionStorage<string>("paymentSessionId", "")
  const [payment, setPayment] = useSessionStorage<string>("payment", "")

  const getStripe = async (): Promise<StripeClient> => {
    const stripe = await stripePromise;
    if (!stripe) {
      console.error('Stripe has not loaded properly.');
      throw new Error('Stripe has not loaded properly.');
    }
    return stripe;
  }

  const createPaymentSession = async (lineItems: LineItem[]): Promise<Payment> => {
    try {
      console.log("Payment request being sent:");
      console.log(JSON.stringify(lineItems));

      // Wrap lineItems in an object if necessary for the API
      const response = await axios.post(getPaymentApiUrl(), lineItems, {
        headers: { 'Content-Type': 'application/json' },
      });

      console.log("Payment response received:");
      console.log(response);

      // Check the response status and log if it's not 200
      if (response.status !== 200) {
        console.error(`Unexpected status code: ${response.status}`);
        throw new Error('Unexpected response from the server.');
      }

      return response.data?.data;
    } catch (error: any) {
      // Log the full error for more information
      console.error('Failed to create a Stripe session:', error);

      if (error.response) {
        // Server responded with a status code out of the 2xx range
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Request data:', error.request);
      } else {
        // Something happened while setting up the request
        console.error('Error message:', error.message);
      }

      throw new Error('Failed to create a Stripe session.');
    }
  };

  const getPaymentSession = async (): Promise<any> => {
    if (paymentSessionId) {
      try {
        // Fetch the session details from your server, passing the sessionId
        const response = await axios.get(`${getPaymentApiUrl()}/${paymentSessionId}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return response.data?.data;
      } catch (error: any) {
        console.error('Error fetching payment session:', error?.message);
        throw new Error('Error fetching payment session.');
      }
    } else {
      throw new Error('Session ID not found in URL.');
    }
  };

  const getPayment = async (): Promise<Payment> => {
    return JSON.parse(payment);
  };

  const clearPayment = async () => {
    setPayment("");
  };

  return { createPaymentSession, getPaymentSession, getStripe, getPayment, setPayment, clearPayment };
};

export default usePaymentApi;
