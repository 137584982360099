import { useAuth0 } from "@auth0/auth0-react";
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ProductList from "../products/component/ProductList";

const Account: React.FC = () => {

  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to access your account.</div>;
  }

  return (
    <Container>

      <div>
        <h1>Account</h1>
        <img src={user?.picture} alt={user?.name} />
        <h2>{user?.name}</h2>
        <p>Email: {user?.email}</p>
        <p>ID: {user?.sub}</p>
        <ProductList userId={user?.sub} />
      </div>
    </Container>
  );
};

export default Account;
